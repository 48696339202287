<template>
  <mobile-screen :header="true" screen-class="profile-screen">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="translations-header">
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("translations", "language-select", "title") }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <ul class="profile-data-list">
      <li class="label">
        {{ displayLabelName("translations", "language-select", "label") }}
      </li>
      <li class="info">
        <dl class="profile-data-definition-list">
          <template v-if="selectedLanguage">
            <dt class="label">
              <span>{{
                displayLabelName("translations", "language-select", "language")
              }}</span>
            </dt>
            <dd class="data">
              <router-link :to="{ name: 'r_translations-select-language' }"
                >{{ displayLanguage(selectedLanguage.id) }}
                <svg-icon icon="arrow-right"
              /></router-link>
            </dd>
          </template>
          <dd class="label" v-else>
            <router-link :to="{ name: 'r_translations-select-language' }"
              >{{
                displayLabelName("translations", "language-select", "language")
              }}
              <svg-icon icon="arrow-right"
            /></router-link>
          </dd>
        </dl>
      </li>
    </ul>
    <template v-slot:footer>
      <div class="submit-action">
        <button
          class="btn rounded light"
          :disabled="!selectedLanguage"
          @click="$router.push({ name: 'r_translations-select-section' })"
        >
          {{ displayLabelName("translations", "language-select", "button") }}
        </button>
      </div>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import { mapState } from "vuex";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import languagesMixin from "@/services/mixins/languages/languages-mixin";

export default {
  name: "Translations",
  computed: {
    ...mapState("language", ["selectedLanguage"])
  },
  mixins: [languagesMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  }
};
</script>
